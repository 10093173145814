<template>
  <section id="add-workShift">
    <div class="add-student-body px-2">
      <div
        class="w-100 py-2"
      >
        <b-card>
          <h2 class="brand-text text-primary text-main-header">
            {{ isUpdate? 'Cập nhật' : 'Thêm mới' }} Công Văn
          </h2>
          <validation-observer ref="documentSchoolDTOS">
            <b-row class="col-md-12 col-12 mt-5">
              <b-col class="col-md-4 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Số, ký hiệu văn bản"
                    rules="required"
                  >
                    <label
                      class="label-add-work-shift"
                      for="documentCode"
                    >Số, ký hiệu văn bản<span class="text-danger"> (*)</span></label>
                    <input
                      id="documentCode"
                      v-model="documentSchoolDTOS.documentCode"
                      rules="required"
                      type="text"
                      class="form-control"
                      placeholder="Nhập số, ký hiệu văn bản"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-4 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Ngày, tháng văn bản"
                    rules="required"
                  >
                    <label
                      class="label-add-work-shift"
                    >
                      Ngày, tháng văn bản<span class="text-danger"> (*)</span></label>
                    <flat-pickr
                      v-model="documentSchoolDTOS.documentDate"
                      class="form-control"
                      :config="configFlatpickr"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-4 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Tên loại và trích yếu nội dung văn bản"
                    rules="required"
                  >
                    <label
                      class="label-add-work-shift"
                      for="name"
                    >
                      Tên loại và trích yếu nội dung văn bản<span class="text-danger"> (*)</span></label>
                    <input
                      id="name"
                      v-model="documentSchoolDTOS.name"
                      rules="required"
                      type="text"
                      class="form-control"
                      placeholder="Nhập tên loại và trích yếu nội dung văn bản"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
            </b-row>
            <b-row class="col-md-12 col-12">
              <b-col class="col-md-4 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Người ký"
                    rules="required"
                  >
                    <label
                      class="label-add-work-shift"
                      for="signer"
                    >Người ký<span class="text-danger"> (*)</span></label>
                    <input
                      id="signer"
                      v-model="documentSchoolDTOS.signer"
                      rules="required"
                      type="text"
                      class="form-control"
                      placeholder="Người ký"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-4 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Nơi nhận văn bản"
                    rules="required"
                  >
                    <label
                      class="label-add-work-shift"
                      for="recipient"
                    >
                      Nơi nhận văn bản<span class="text-danger"> (*)</span></label>
                    <input
                      id="recipient"
                      v-model="documentSchoolDTOS.recipient"
                      rules="required"
                      type="text"
                      class="form-control"
                      placeholder="Nơi nhận văn bản"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-4 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Đơn vị hoặc người nhận bản lưu"
                    rules="required"
                  >
                    <label
                      class="label-add-work-shift"
                      for="storage"
                    >
                      Đơn vị hoặc người nhận bản lưu<span class="text-danger"> (*)</span></label>
                    <input
                      id="storage"
                      v-model="documentSchoolDTOS.storage"
                      rules="required"
                      type="text"
                      class="form-control"
                      placeholder="Nhập đơn vị hoặc người nhận bản lưu"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
            </b-row>
            <b-row class="col-md-12 col-12">
              <b-col class="col-md-4 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Số lượng bản"
                    rules="required"
                  >
                    <label
                      class="label-add-work-shift"
                      for="count"
                    >Số lượng bản<span class="text-danger"> (*)</span></label>
                    <input
                      id="count"
                      v-model="documentSchoolDTOS.count"
                      rules="required"
                      type="number"
                      class="form-control"
                      placeholder="Nhập số lượng bản"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-4 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Ghi chú"
                    rules="required"
                  >
                    <label
                      class="label-add-work-shift"
                      for="note"
                    >
                      Ghi chú<span class="text-danger"> (*)</span></label>
                    <input
                      id="note"
                      v-model="documentSchoolDTOS.note"
                      rules="required"
                      type="text"
                      class="form-control"
                      placeholder="Nhập ghi chú"
                    >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="col-md-4 col-12 mt-1">
                <div class="form-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Loại công văn"
                    rules="required"
                  >
                    <b-form-group>
                      <label class="font-weight-bold font-size-label-custom">Loại công văn<span class="text-danger"> (*)</span></label>
                      <v-select
                        v-model="documentSchoolDTOS.documentType"
                        placeholder="Chọn loại công văn"
                        label="label"
                        :options="listDocumentType"
                        :reduce="(department) => department.value"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
            </b-row>
            <b-row class="col-md-12 col-12">
              <b-col lg="6">
                <div class="push-file">
                  <label
                    class="font-size-label-custom font-weight-bold"
                  >File đính kèm (Ảnh, pdf)</label>
                  <b-form-file
                    id="imgpdf"
                    multiple
                    browse-text="Chọn file"
                    accept="image/png, image/gif, image/jpeg, application/pdf"
                    placeholder="Vui lòng chọn file"
                    @input="importFileAttach"
                  />
                </div>
              </b-col>
              <b-col lg="6">
                <div
                  v-if="listFileShowTable.length > 0"
                  class="data-file-import mt-2"
                >
                  <b-table
                    :items="listFileShowTable"
                    :fields="fieldFile"
                  >
                    <template #cell(index)="data">
                      <span>{{ data.index + 1 }}</span>
                    </template>
                    <template
                      v-if="listFileShowTable.length > 0"
                      #cell(hanhdong)="data"
                    >
                      <b-button
                        v-if="data.item.id"
                        v-b-tooltip.hover.top="'Xóa'"
                        variant="flat-danger"
                        class="btn-icon"
                        @click="deleteFile(data.item)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <b-button
                        v-if="data.item.id === null"
                        v-b-tooltip.hover.top="'Xóa'"
                        variant="flat-danger"
                        class="btn-icon"
                        @click="deletFileNoCallApi(data.item)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                      <b-button
                        v-if="data.item.id"
                        v-b-tooltip.hover.top="'Tải file'"
                        variant="flat-success"
                        class="btn-icon"
                        @click="downFileCallApi(data.item)"
                      >
                        <feather-icon icon="DownloadIcon" />
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
            <div class="button-box-footer float-right mt-1">
              <div class="demo-inline-spacing">
                <b-button
                  v-if="!isUpdate"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="addDocument"
                >
                  Thêm mới
                </b-button>
                <b-button
                  v-if="isUpdate"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="addDocument"
                >
                  Cập nhật
                </b-button>
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  :to="{ name: 'manage-document'}"
                  variant="outline-danger"
                >
                  Quay lại
                </b-button>
              </div>
            </div>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BButton, BFormFile, BTable, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    vSelect,
    BFormFile,
    BTable,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      fieldFile: [
        {
          key: 'index', label: 'stt',
        },
        {
          key: 'fileName', label: 'Tên file',
        },
        {
          key: 'hanhdong', label: 'Hành động',
        },
      ],
      isClickFile: false,
      listFileImport: [],
      listFileShowTable: [],
      listPustFileEndcoedBase64: [
      ],
      required,
      dir: 'rtl',
      isUpdate: false,
      documentSchoolDTOS: {},
      idDocument: null,
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
      listDocumentType: [
        { value: 0, label: 'Khác' },
        { value: 1, label: 'Công văn đi' },
        { value: 2, label: 'Công văn đến' },
      ],
    }
  },
  computed: {
  },
  created() {
    const parameters = this.$route.query
    if (parameters.id) {
      this.isUpdate = true
      this.idDocument = parameters.id
      this.getInfoDocument()
    }
  },
  methods: {
    downFileCallApi(item) {
      this.$crm.get(`file/${item.fileName}`)
        .then(res => {
          const accesstoken = localStorage.getItem('access_token')
          window.open(`${res.config.baseURL}${res.config.url}?access_token=${accesstoken}`, '_blank')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thành công',
              icon: 'CheckIcon',
              text: 'Tải file thành công',
            },
          })
        })
    },
    deletFileNoCallApi(item) {
      const index = this.listFileShowTable.indexOf(item)
      if (index > -1) {
        this.listFileShowTable.splice(index, 1)
        this.listPustFileEndcoedBase64.splice(index, 1)// 2nd parameter means remove one item only
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thành công!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Xóa file thành công',
          },
        })
      }
    },
    deleteFile(item) {
      this.$crm.post('document-school/delete-profile', { fileName: item.fileName, documentSchoolId: item.documentSchoolId, id: item.id }).then(res => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thành công!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Xóa file thành công',
          },
        })
        const index = this.listFileShowTable.indexOf(item)
        this.listFileShowTable.splice(index, 1)
        this.listPustFileEndcoedBase64.splice(index, 1)
        this.listFileImport.splice(index, 1)
        this.convertFileBase64()
      })
    },
    importFileAttach(file) {
      
      this.isClickFile = true
      if (this.isClickFile === true) {
        this.listFileImport = this.listFileImport.concat(file)
      }
      const listArr = []
      if (this.listFileShowTable.length > 0 && this.idStudent) {
        for (let i = 0; i < file.length; i++) {
          const sizeFile = file[i].size
          const types = file[i].type
          if (sizeFile <= 1000141 && types !== 'application/pdf') {
            listArr.push({ fileName: file[i].name, id: null })
          } else if (sizeFile > 1000141 && types !== 'application/pdf') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Lỗi tải file, ${file[i].name}`,
                icon: 'XIcon',
                text: 'file tải lên không được vượt quá 1Mb',
                variant: 'danger',
              },
            })
          }
        }
        this.listFileShowTable = this.listFileShowTable.concat(listArr)
      } else {
        for (let i = 0; i < file.length; i++) {
          if (file[i].size <= 1000141 && file[i].type !== 'application/pdf') {
            listArr.push({ fileName: file[i].name, id: null })
          }
          if (file[i].size > 1000141 && file[i].type !== 'application/pdf') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Lỗi tải file, ${file[i].name}`,
                icon: 'XIcon',
                text: 'file tải lên không được vượt quá 1Mb',
                variant: 'danger',
              },
            })
          }
          if (file[i].size <= 5000705 && file[i].type === 'application/pdf') {
            listArr.push({ fileName: file[i].name, id: null })
          } else if (file[i].size > 5000705 && file[i].type === 'application/pdf') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Lỗi tải file, ${file[i].name}`,
                icon: 'XIcon',
                text: 'file pdf tải lên không được vượt quá 5Mb',
                variant: 'danger',
              },
            })
          }
        }
        this.listFileShowTable = this.listFileShowTable.concat(listArr)
      }
      if (this.listFileShowTable.length > 5) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi tải file',
            icon: 'XIcon',
            text: 'File tải lên không được vượt quá 5 file',
            variant: 'danger',
          },
        })
        return
      }
      this.convertFileBase64()
    },
    convertFileBase64() {
      const arrFileEncode = [
      ]
      if (this.listFileImport.length > 0) {
        for (let i = 0; i < this.listFileImport.length; i++) {
          const reader = new FileReader()
          if (this.listFileImport[i].size <= 1000141) {
            reader.readAsDataURL(this.listFileImport[i])
            reader.onload = () => {
              const base64 = reader.result
              arrFileEncode.push({ file: base64, fileName: this.listFileImport[i].name, id: null })
            }
          }
          this.listPustFileEndcoedBase64 = arrFileEncode
        }
      }
    },
    getInfoDocument() {
      const { id } = this.$route.query
      if (!id) {
        return
      }
      this.$crm.get(`document-school/find-by-id/${this.idDocument}`).then(response => {
        if (response.data) {
          this.documentSchoolDTOS = response.data
          this.listFileShowTable = response.data.documentProfileSchools
        }
      })
    },
    addDocument() {
      this.$refs.documentSchoolDTOS.validate().then(success => {
        if (success) {
          this.documentSchoolDTOS.uploadDocumentProfileSchools = this.listPustFileEndcoedBase64
          const url = !this.isUpdate ? 'document-school/create' : `document-school/update/${this.idDocument}`
          this.$crm.post(url, !this.isUpdate ? [this.documentSchoolDTOS] : this.documentSchoolDTOS).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: this.isUpdate ? 'Cập nhật công văn thành công' : 'Tạo mới công văn thành công',
              },
            })
            this.$router.push({
              name: 'manage-document',
            })
          })
        }
      })
    },
    confirmDeleteRecord(index) {
      this.selectedWifiId = index
      this.$swal({
        title: 'Xác nhận xóa',
        text: 'Bạn có chắc là muốn xóa ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteRecord()
        }
      })
    },
    deleteRecord() {
      this.documentSchoolDTOS.splice(this.selectedWifiId, 1)
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select';
@import '../../@core/scss/custom/manager-workShift';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
@import 'src/@core/scss/vue/pages/page-auth.scss';
@import '../../assets/scss/add-student';
</style>
